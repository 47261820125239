<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Categories ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.category.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Category
      </v-btn>
    </v-app-bar>

    <div class="mx-4" id="category-list">
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            @change="
              defaultStatus = true
              activeAction()
            "
          >
            Active
          </v-tab>
          <v-tab
            @change="
              defaultStatus = false
              activeAction()
            "
          >
            Archived
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search category"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="categories"
        :options.sync="options"
        v-model="selected"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetchCategories"
        @pagination="handleScrollAfterTableRender"
      >
        <template #body="props">
          <draggable
            :list="categories"
            tag="tbody"
            @end="(event) => onDropCallback(event, options)"
            :move="(event) => onMoveCallback(event, options)"
          >
            <data-table-row-handler
              v-for="(item, index) in props.items"
              :key="index"
              :item="item"
              :headers="headers"
              @click="editItem(item)"
            >
              <template #item.is_active="{ item }">
                <v-checkbox
                  v-model="item.is_active"
                  disabled
                  readonly
                  class="mt-0 mb-0"
                />
              </template>
              <template #item.buildingName="{ item }">
                <div
                  v-for="buildingItem in item.buildings"
                  :key="buildingItem.id"
                >
                  {{ buildingItem.name }}
                </div>
              </template>
              <template #item.created_at="{ item }">
                <div v-if="item.addedDate">{{ item.addedDate }}</div>
              </template>
              <template #item.updated_at="{ item }">
                <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
              </template>
            </data-table-row-handler>
          </draggable>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Category list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/common/DataTableRowHandler.vue'

export default {
  name: 'CategoriesPage',

  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    draggable,
    DataTableRowHandler,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      selected: [],
      options: {},
      defaultFilter: 'label',
      sortOrder: false,
      defaultStatus: true,
      filterBy: [
        {
          value: 'label',
          label: 'Category',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
        {
          value: 'updated_at',
          label: 'Updated date',
        },
      ],
      headers: [
        {
          text: 'Category',
          value: 'label',
        },
        {
          text: 'Building(s)',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Active',
          value: 'is_active',
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
    }
  },

  created() {
    this.clearCategories()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
      listMeta: (state) => state.category.listMeta,
      paramsCategory: (state) => state.category.queryParams,
      scrollPosition: (state) => state.category.scrollPosition,
      initCategories: (state) => state.category.initList,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      updatePositions: 'category/updatePositions',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'category/setQueryParams',
      setScrollPosition: 'category/setScrollPosition',
    }),

    fetchInitParams() {
      if (!this.paramsCategory) {
        this.fetchCategories()
        return
      }
      if (this.paramsCategory?.filter) {
        this.defaultStatus = this.paramsCategory.filter
      }

      if (this.paramsCategory?.sort) {
        if (this.paramsCategory.sort[0].includes('-')) {
          this.defaultFilter = this.paramsCategory.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsCategory?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'label'
      }

      if (this.paramsCategory?.search) {
        this.filter.search = this.paramsCategory.search
      }

      this.fetchCategories(null, 1, {
        ...this.paramsCategory,
        type: CATEGORY_TYPE.general,
        paginate: true,
      })
    },

    activeAction() {
      this.clearCategories()
      this.fetchCategories()
    },

    filterList(filterValue) {
      this.clearCategories()
      this.fetchCategories(null, filterValue)
    },

    search: debounce(function () {
      this.clearCategories()
      this.fetchCategories()
    }, 600),

    async fetchCategories(options, page = 1, paramsCategory = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options.itemsPerPage === -1
            ? this.totalTags
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy?.[0]
          ? options.sortDesc?.[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : 'position',
        paginate: true,
        type: CATEGORY_TYPE.general,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getCategories(paramsCategory || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
      this.setParams(params)
    },

    async changePositions(items) {
      await this.updatePositions({ items: items }).then((data) => {
        this.showSnackbar('Updated positions successfully!')
      })
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.category.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    onMoveCallback(event, options) {
      return options.sortBy?.length === 0
    },

    onDropCallback(evt, options) {
      const fromIndex = evt.oldIndex // Original index of the dragged item
      const toIndex = evt.newIndex

      if (options.sortBy?.length > 0 || fromIndex === toIndex) {
        return false
      }

      // Extract positions from the original list
      const sortedPositions = this.initCategories.map((item) => item.position)

      // Map over the new list and reassign positions
      const listResult = this.categories.map((item, index) => ({
        id: item.id,
        position: sortedPositions[index],
      }))

      this.changePositions(listResult)
      return true
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
